<script setup lang="ts">
export interface Props {
    prefix?: string;
    shiny?: boolean;
    suffixSingular?: string;
    suffixPlural?: string;
    value: number;
    whenZero?: string;
}

const props = withDefaults(defineProps<Props>(), {
    prefix: 'Show',
    shiny: true,
    suffixSingular: 'Result',
    suffixPlural: 'Results',
    whenZero: undefined,
});
const emit = defineEmits<{
    click: [];
}>();

const isSingular = ref(props.value === 1);

const showingCustomZeroText = computed(() => {
    return props.value === 0 && props.whenZero !== undefined;
});

const prefixText = computed(() => {
    if (showingCustomZeroText.value) return null;
    return props.prefix;
});

const suffixText = computed(() => {
    if (showingCustomZeroText.value) return null;
    return isSingular.value ? props.suffixSingular : props.suffixPlural;
});

const pluralFormChange = ({ isSingular: val }: { isSingular: boolean }) => {
    isSingular.value = val;
};
</script>

<template>
    <BaseButton :shiny="props.shiny">
        <button
            tabindex="0"
            type="button"
            class="show-results-button"
            @click="emit('click')"
        >
            <span v-if="prefixText"> {{ prefixText }}&nbsp; </span>

            <span v-if="showingCustomZeroText">{{ props.whenZero }}</span>

            <AnimatedNumber
                v-else
                :value="value"
                @plural-form-change="pluralFormChange"
            />

            <span v-if="suffixText"> &nbsp;{{ suffixText }} </span>
        </button>
    </BaseButton>
</template>

<style lang="postcss" scoped>
.show-results-button {
    display: block;
}
</style>
